import axios from '@axios'
import { db, auth, storage } from '@/Firebase/Firebase.service'
import { collectionGroup, collection, getDocs, getDoc, doc, updateDoc, addDoc, where, query, DocumentSnapshot, orderBy, startAt } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage";
/*j.bendix@epiito.com*/

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        var currentCompany = JSON.parse(localStorage.getItem('company'))
        getDocs(collection(db, `/companies/${currentCompany.company.id}/projects`)).then(projectsCollection => {

          //set lower/higher range for search
          var lowerRange = queryParams.options.itemsPerPage * (queryParams.options.page -1);
          var higherRange = lowerRange + queryParams.options.itemsPerPage;

          var projectDocs = []
          projectsCollection.forEach((project) => {
            projectDocs.push(project)
          })

          projectDocs = projectDocs.slice(lowerRange, higherRange);


          var filteredData = []
          var total = 0

          new Promise((resolve2, reject2) => {
            projectDocs.forEach((project) => {
              //need to get a username to display
              getDoc(project.data().uploadedBy).then(userDoc => {
                getDownloadURL(ref(storage, `${project.data().storageAdress}/thumbnail.png`)).then((thumbnailUrl) => {
                  total++
                  filteredData.push({
                    shortId: project.id,
                    id: project._key.path.toString(),
                    dateCreated: project.data().dateCreated.toDate(),
                    description: project.data().description,
                    name: project.data().name,
                    size: project.data().size,
                    storageAdress: project.data().storageAdress,
                    thumbnail: thumbnailUrl,
                    uploadedBy: project.data().uploadedBy,
                    uploadedByUsername: userDoc.data().username,
                    version: project.data().version,
                  })
                  if (total == projectDocs.length) {
                    resolve2()
                  }
                })

              })
            })
            //when all info is downloaded, we can resolve the foreach promise

          }).then(() => {
            
            var data = {
              filteredData: filteredData,
              total: projectsCollection.size
            }
            var response =
            {
              data: data
            }
            console.log("resolve ok")
            console.log(filteredData)
            resolve(response)
          })
        }).catch(error => reject(error))
      })
    },
    fetchProject(ctx, { id }) {
      return new Promise((resolve, reject) => {
        var projectRef = doc(db, id)

        getDoc(projectRef).then(project => {
          getDoc(project.data().uploadedBy).then(userDoc => {
            getDownloadURL(ref(storage, `${project.data().storageAdress}/thumbnail.png`)).then((thumbnailUrl) => {
              var data = {
                id: project._key.path.toString(),
                dateCreated: project.data().dateCreated.toDate(),
                description: project.data().description,
                name: project.data().name,
                size: project.data().size,
                storageAdress: project.data().storageAdress,
                thumbnail: thumbnailUrl,
                uploadedBy: project.data().uploadedBy,
                uploadedByUsername: userDoc.data().username,
                version: project.data().version,
              }
              var response = {
                data
              }
              resolve(response)
            })
          })
        })
      })
    },
    pushProjectChanges(ctx, { id, newName, newDescription }) {
      console.log("asdasd")

      return new Promise((resolve, reject) => {
        //get document
        var projectRef = doc(db, id)
        updateDoc(projectRef, {
          description: newDescription,
          name: newName
        }).then(() =>{
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}

