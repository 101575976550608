<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-img v-if="!projectData.thumbnail"
                 :src="require(`@/assets/epiito/images/avatar.png`)"></v-img>

          <v-img contain
                 v-if="projectData.thumbnail"
                 :src="projectData.thumbnail"></v-img>
        </v-card-title>
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>
          <v-divider></v-divider>



          <!--<v-text-field color="success"
          loading
          disabled></v-text-field>-->


          <v-text-field :readonly="!editing.editing"
                        v-model="projectData.name"
                        label="Name"></v-text-field>

          <v-text-field readonly
                        v-model="projectData.dateCreated"
                        label="Date created"></v-text-field>

          <!--<v-text-field v-model=" projectData.size"
          label="Size"></v-text-field>-->

          <v-text-field readonly
                        v-model="projectData.uploadedByUsername"
                        label="Uploaded By:"></v-text-field>


          <v-textarea auto-grow
                      :readonly="!editing.editing"
                      clearable
                      clear-icon="mdi-close-circle"
                      label="Description"
                      v-model="projectData.description"
                      value="This is clearable text."></v-textarea>

          <v-row justify="end">

            <v-btn color="primary"
                   type="submit"
                   class="mt-6 mr-6"
                   v-show="!editing.editing"
                   @click.prevent="StartEdit()">
              Edit
            </v-btn>

            <v-btn color="primary"
                   type="submit"
                   class="mt-6 mr-6"
                   :loading="editing.saving"
                   :disabled="editing.saving"
                   v-show="editing.editing"
                   @click.prevent="SaveEdit()">
              Save
            </v-btn>

            <v-btn type="info"
                   class="mt-6 mr-6"
                   v-show="editing.editing"
                   @click.prevent="CancelEdit()">
              Cancel
            </v-btn>

          </v-row>
          <v-row justify="end">
            <span class="mr-6">
              {{editing.text}}
            </span>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
  import { onUnmounted, ref } from '@vue/composition-api'
  import router from '@/router'
  import store from '@/store'

  // eslint-disable-next-line object-curly-newline
  import { mdiPencil, mdiAccount, mdiAccountOutline, mdiLockOutline, mdiBookmarkOutline, mdiBellOutline, mdiLinkVariant } from '@mdi/js'
  //import UserBioPanel from './user-bio-panel/UserBioPanel.vue'
  import userStoreModule from '../projectStoreModule'
  //import UserTabOverview from './user-tab-overview/UserTabOverview.vue'


  export default {
    components: {
      //UserBioPanel,
      //UserTabOverview,
    },

    setup() {
      const USER_APP_STORE_MODULE_NAME = 'app-project'

      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      })

      const projectData = ref({})
      store
        .dispatch('app-project/fetchProject', { id: router.currentRoute.params.id })
        .then(response => {
          projectData.value = response.data
        })
        .catch(error => {
          console.log("error getting projectData " + error)
        })
      const tabs = [
        { icon: mdiAccountOutline, title: 'Overview' },
      ]


      return {
        tabs,
        projectData,
        icons: {
          mdiAccountOutline,
          mdiPencil,
        }
      }
    },

    methods: {
      StartEdit() {
        //set ui
        this.editing.editing = true
        //save old data
        this.editing.oldDescription = this.projectData.description
        this.editing.oldName = this.projectData.name
      },

      CancelEdit() {
        //set ui
        this.editing.editing = false
        //restore data
        this.projectData.description = this.editing.oldDescription
        this.projectData.name = this.editing.oldName
      },

      SaveEdit() {
        this.editing.saving = true;

        store
          .dispatch('app-project/pushProjectChanges', {
            id: router.currentRoute.params.id,
            newName: this.projectData.name,
            newDescription: this.projectData.description
          })
          .then(response => {
            this.editing.saving = false;
            this.editing.editing = false;
            this.editing.text = "Changes saved";

          })
          .catch(error => {
            this.editing.saving = false;
            this.editing.editing = false;
            this.projectData.description = this.editing.oldDescription
            this.projectData.name = this.editing.oldName
            this.editing.text = "An error has occured, please try again later";
          })


      },


    },

    data: () => ({
      editing: { editing: false, saving: false, text: "", color: "success" },
      icons: {
        mdiAccountOutline,
        mdiPencil
      }
    })
  }
</script>

<style lang="scss" scoped>
  @import '@core/preset/preset/apps/user.scss';
  /*  v-text-field ::v-deep label {
    font-size: 1.5em;
  }
  .v-textarea ::v-deep label {
    font-size: 1.5em;
  }*/

</style>
